import { ProductJsonLd } from 'next-seo';

import { ProductByHandleQuery } from '~lib/shopify/sdk';

interface Props {
  product: ProductByHandleQuery['productByHandle'];
  sku: string;
  reviews?: {
    summary: Record<string, any>;
    items: Record<string, any>[];
  };
}

export const ProductStructuredData = ({ product, sku, reviews }: Props) => {
  const review =
    reviews?.items?.length > 0
      ? {
          review: {
            reviewRating: {
              ratingValue: reviews.items[0]?.stars,
              bestRating: '5',
              worstRating: '0',
            },
            author: {
              name: reviews.items[0]?.consumer?.displayName,
            },
          },
        }
      : {};

  return (
    <ProductJsonLd
      productName={product.title}
      images={product.images?.edges?.map(({ node }) => node.url)}
      description={product?.description}
      brand={product?.vendor}
      offers={
        product?.variants?.edges?.map(({ node }) => ({
          name: node.selectedOptions?.map(({ value }) => value).join(', '),
          price: parseFloat(node.priceV2?.amount),
          priceCurrency: node.priceV2?.currencyCode,
          itemCondition: 'http://schema.org/NewCondition',
          availability: `http://schema.org/${
            node?.availableForSale ? 'InStock' : 'OutOfStock'
          }`,
          url: `/products/${product.handle}?variant=${node.sku}`,
          sku: node?.sku,
        })) as any
      }
      sku={sku}
      {...(reviews ? {
        aggregateRating: {
          ratingValue: reviews?.summary?.starsAverage,
          reviewCount: reviews?.summary?.numberOfReviews?.total ?? 0,
          bestRating: '5',
        },
        reviews: reviews.items.map((review) => ({
          reviewRating: {
            ratingValue: review.stars,
            bestRating: '5',
            worstRating: '0',
          },
          author: {
            type: 'Person',
            name: reviews.items[0]?.consumer?.displayName,
          },
          datePublished: review.createdAt,
          reviewBody: review.text,
          name: review.title,
        })),
        ...review
      } : {})}
    />
  );
};
