import { useEffect, useState, useRef } from 'react';
import { Box, Text, SystemProps } from '@storyofams/react-ui';
import { isEqual } from 'lodash';
import { useRouter } from 'next/router';
import SbEditable from 'storyblok-react';

import { Heading, Divider, Container } from '~components';
import { ProductSlider } from '~components/product/sections';
import { getShopifySdk } from '~lib/shopify/client';
import { ProductByHandleQuery } from '~lib/shopify/sdk';

type ProductSectionProps = {
  content?: {
    title?: string;
    description?: string;
    _editable?: string;
    products?: any;
  };
  products?: ProductByHandleQuery['productByHandle'][];
  first?: boolean;
  divider?: boolean;
} & SystemProps;

export const ProductSection = ({
  content,
  first,
  products: initialProducts,
  divider = true,
  ...props
}: ProductSectionProps) => {
  const { locale } = useRouter();
  const [products, setProducts] = useState(initialProducts);
  const storyblokProducts = useRef(content?.products);

  const updateProducts = async () => {
    const productsArray = (
      await Promise.all(
        content.products.item
          ?.filter((p) => !!p.blob.handle)
          ?.map((p) =>
            getShopifySdk(locale)
              .productByHandle({
                // @ts-ignore
                handle: p.blob.handle,
              })
              .then((res) => res.productByHandle)
              .catch((err) => console.log(err)),
          ),
      )
    )?.filter((p) => !!p) as ProductByHandleQuery['productByHandle'][];

    setProducts(productsArray);
  };

  useEffect(() => {
    if (!isEqual(storyblokProducts.current, content.products)) {
      storyblokProducts.current = content.products;

      updateProducts();
    }
  }, [content?.products]);

  return (
    <SbEditable content={content as any}>
      <Box width="100%" overflow="hidden">
        <Container
          as="section"
          textAlign="center"
          pt={0}
          pb={[8, 10]}
          px={[2, 6]}
          {...props}
        >
          {divider && <Divider mb={[8, 10]} />}
          {!!content?.title && (
            <Heading
              as={first ? 'h1' : 'h2'}
              variant="h1"
              mb={!!content?.description ? [1, 2] : { all: 4, md: 5 }}
              maxWidth="720px"
              mx="auto"
            >
              {content?.title}
            </Heading>
          )}
          {!!content?.description && (
            <Text
              fontSize={[2, 2.25]}
              mb={{ all: 4, md: 5 }}
              maxWidth="480px"
              mx="auto"
            >
              {content?.description}
            </Text>
          )}
          <ProductSlider items={products} />
        </Container>
      </Box>
    </SbEditable>
  );
};
