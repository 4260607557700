import { Box, SystemProps, Flex, css } from '@storyofams/react-ui';
import { Image as StoryblokImage } from '@storyofams/storyblok-toolkit';

import {
  Heading,
  Text,
  FixedRatio,
  HoverArea,
  ScaleOnHover,
} from '~components';

interface ImageProps extends SystemProps {
  title?: string;
  description?: string;
  alignment: 'landscapeLeft' | 'landscapeRight' | 'center';
  type: 'landscape' | 'portrait';
  image: any;
  asCard?: boolean;
  asLink?: boolean;
}

export const Image = ({
  title,
  description,
  type,
  image,
  alignment,
  asCard,
  asLink,
  ...props
}: ImageProps) => {
  const space = { all: 2.25, sm: 0 };
  const imgStyles = {
    landscape: {
      borderRadius: alignment === 'center' ? [0, 'lg'] : { sm: 'lg' },
      ...(alignment === 'landscapeRight'
        ? {
            borderTopLeftRadius: 'lg',
            borderTopRightRadius: [0, 'lg'],
            borderBottomLeftRadius: asCard ? 0 : 'lg',
            borderBottomRightRadius: [0, asCard ? 0 : 'lg'],
          }
        : alignment === 'landscapeLeft'
        ? {
            borderTopLeftRadius: [0, 'lg'],
            borderTopRightRadius: 'lg',
            borderBottomLeftRadius: [0, asCard ? 0 : 'lg'],
            borderBottomRightRadius: asCard ? 0 : 'lg',
          }
        : {}),
    },
    portrait: {
      borderTopLeftRadius: 'lg',
      borderTopRightRadius: 'lg',
      borderBottomLeftRadius: asCard ? 0 : 'lg',
      borderBottomRightRadius: asCard ? 0 : 'lg',
    },
  };

  const wrapperStyles = {
    landscape: {
      ...(alignment === 'landscapeRight'
        ? { pl: [2, 0] as any }
        : alignment === 'landscapeLeft'
        ? { pr: [2, 0] as any }
        : { px: 0 as any }),
    },
    portrait: {
      px: [2, 0] as any,
    },
  };

  const cardStyles = {
    landscape: {
      borderBottomLeftRadius: [
        alignment === 'landscapeLeft' ? 0 : 'lg',
        'lg',
      ] as any,
      borderBottomRightRadius: [
        alignment === 'landscapeRight' ? 0 : 'lg',
        'lg',
      ] as any,
    },
    portrait: {
      borderBottomLeftRadius: 'lg',
      borderBottomRightRadius: 'lg',
    },
  };

  return (
    <Flex
      flexDirection="column"
      alignItems={title || asCard ? 'center' : 'left'}
      textAlign={title || asCard ? 'center' : 'left'}
      width="100%"
      maxWidth={
        // make it compatible with what the width of the landscape image in the grid would be
        alignment === 'center'
          ? { sm: 'calc(calc(100% - 120px)/22*13)', lg: '680px' }
          : { lg: '680px' }
      }
      css={css({
        '&:first-child': {
          marginBottom: [asCard ? 6 : 10, 0],
        },
      })}
      {...wrapperStyles[type]}
      {...props}
    >
      <HoverArea>
        <FixedRatio
          ratio={
            type === 'landscape'
              ? [
                  [352, 277],
                  [676, 400],
                ]
              : [
                  [327, 402],
                  [500, 586],
                ]
          }
          position="relative"
          overflow="hidden"
          width="100%"
          css={css({
            ...imgStyles[type],
            '.storyblok-image-wrapper': {
              width: '100%',
              height: '100%',
              img: { ...imgStyles[type] },
            },
          })}
          bg="grey200"
        >
          {asLink ? (
            <ScaleOnHover>
              <StoryblokImage
                src={image?.filename}
                alt={image?.alt || title}
                fluid={type === 'portrait' ? [515, 602] : 800}
                focus={image?.focus}
                smart
              />
            </ScaleOnHover>
          ) : (
            <StoryblokImage
              src={image?.filename}
              alt={image?.alt || title}
              fluid={type === 'portrait' ? [515, 602] : 800}
              focus={image?.focus}
              smart
            />
          )}
        </FixedRatio>

        <Box
          px={space as any}
          {...(asCard
            ? {
                bg: 'white',
                width: '100%',
                pb: [5],
                px: 2.25,
                ...cardStyles[type],
              }
            : {})}
        >
          {title && (
            <Heading variant="h3" as="h3" pt={{ all: 3, sm: 4 }}>
              {title}
            </Heading>
          )}

          {description && (
            <Text mt={1} opacity={!title ? 1 : 0.7}>
              {description?.substring(0, 70)}
              {description?.length > 70 ? '...' : ''}
            </Text>
          )}
        </Box>
      </HoverArea>
    </Flex>
  );
};
