import React from 'react';
import { Flex, Icon, Box, Stack } from '@storyofams/react-ui';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Text } from '~components';
import { Star, StarHalf, StarOutline } from '~components/common/Icons';

const messages = defineMessages({
  buyer: 'Verified buyer',
});

export const Stars = ({ stars, ...props }) => {
  const roundedRating = Math.round(stars * 2) / 2;

  return (
    <Stack space={0.75} {...props}>
      {[1, 2, 3, 4, 5].map((i) => {
        switch (true) {
          case i <= roundedRating:
            return (
              <Icon
                key={`star-${i}`}
                icon={Star}
                width="13px"
                color="grey900"
              />
            );
          case i - 0.5 === roundedRating: //checks for half values
            return (
              <Icon
                key={`star-${i}`}
                icon={StarHalf}
                width="13px"
                color="grey900"
              />
            );
          default:
            return (
              <Icon
                key={`star-${i}`}
                icon={StarOutline}
                width="13px"
                color="grey900"
              />
            );
        }
      })}
    </Stack>
  );
};

export const ReviewsOverview = ({ reviews, ...props }) => (
  <Flex
    alignItems="center"
    justifyContent={{ all: 'center', md: 'flex-start' }}
    {...props}
  >
    <Stars stars={reviews?.score?.stars || reviews?.starsAverage} />

    <Text
      css={{ textTransform: 'uppercase' }}
      color="inherit"
      fontSize={[1.25, 1.5]}
      px={0.75}
      fontWeight="medium"
      lineHeight="11.72px"
      letterSpacing="0.04em"
      mt={0.25}
    >
      {reviews?.numberOfReviews?.total} Reviews
    </Text>
  </Flex>
);

export const Review = ({
  consumer,
  content,
  stars,
  storyblokReview = false,
  ...props
}) => (
  <Box textAlign="left" {...props}>
    <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
      <Text fontSize={2.25} fontWeight="medium" mb={1} lineHeight="21px">
        {consumer.name || consumer.displayName}
      </Text>

      {!storyblokReview && (
        <Text
          fontSize={1.75}
          css={{ textTransform: 'uppercase' }}
          color="grey400"
        >
          <FormattedMessage {...messages.buyer} />
        </Text>
      )}
    </Flex>

    <Stars stars={stars} mb={3} />

    <Text fontSize={[2, 2.25]} maxWidth={{ md: '60%' }}>
      {content}
    </Text>
  </Box>
);
