import { Flex } from '@storyofams/react-ui';

import { Slider } from '~components';
import { ProductByHandleQuery } from '~lib/shopify/sdk';
import { ProductSlide } from './ProductSlide';

interface Props {
  items?: ProductByHandleQuery['productByHandle'][];
  roundImage?: boolean;
}

export const ProductSlider = ({ items }: Props) => {
  const products = items?.map((item) => {
    return {
      ...item,
      image: item?.images?.edges[0]?.node?.url,
      bg: item?.images?.edges[1]?.node?.url,
    };
  });

  return (
    <Flex
      variant="center"
      css={{
        '.swiper-slide': {
          width: '240px',
        },
        '.swiper-button-next, .swiper-button-prev': {
          top: '120px',
          tranform: 'translateY(-50%)',
        },
      }}
    >
      <Slider centerInsufficientSlides>
        {products?.map((product, index) => (
          <ProductSlide
            handle={product.handle}
            image={product.image}
            title={product.title}
            priceRange={product.priceRange}
            bg={product.bg}
            readyToEnjoy={product.readyToEnjoy}
            variants={product.variants}
            id={product.id}
            tags={product.tags}
            position={index + 1}
          />
        ))}
      </Slider>
    </Flex>
  );
};
