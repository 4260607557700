import { Fragment } from 'react';
import { Stack, Flex, css } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';
import SbEditable from 'storyblok-react';

import {
  Heading,
  Text,
  Divider,
  Container,
  ScaleOnHover,
  HoverArea,
  Link,
} from '~components';

export const CallToAction = ({ title, blocks, divider = true, ...props }) => {
  return (
    <Container
      as="section"
      textAlign="center"
      pt={0}
      pb={[8, 10]}
      px={[2, 6]}
      {...props}
    >
      {divider && <Divider mb={[8, 10]} />}

      {!!title && (
        <Heading variant="h1" as="h2" mb={{ all: 4, md: 5 }} flex={1}>
          {title}
        </Heading>
      )}

      <Stack space={[8, 3]} flexDirection={['column', 'row']}>
        {blocks?.map((block) => {
          const { title, description, image, link, _uid } = block;

          return (
            <Fragment key={_uid}>
              <SbEditable content={block}>
                <HoverArea>
                  <Link
                    href={
                      link.story?.url === 'home'
                        ? '/'
                        : link.url || `/${link.story?.url}`
                    }
                    passHref
                    key={_uid}
                  >
                    <Flex
                      as="a"
                      flex={1}
                      flexDirection="column"
                      css={css({
                        '.storyblok-image-wrapper': {
                          height: [340, 400],
                          img: {
                            borderRadius: 'lg',
                          },
                        },
                      })}
                    >
                      <Flex
                        width="100%"
                        height="100%"
                        overflow="hidden"
                        borderRadius="lg"
                      >
                        <ScaleOnHover>
                          <Image
                            src={image.filename}
                            alt={image.alt || title}
                            focus={image.focus}
                            fluid={[blocks.length === 1 ? 1344 : 660, 400]}
                          />
                        </ScaleOnHover>
                      </Flex>

                      <Heading variant="h2" as="h2" mt={4} mb={1}>
                        {title}
                      </Heading>

                      <Text maxWidth="320px" mx="auto">
                        {description}
                      </Text>
                    </Flex>
                  </Link>
                </HoverArea>
              </SbEditable>
            </Fragment>
          );
        })}
      </Stack>
    </Container>
  );
};
