import { Fragment } from 'react';
import { Box, SystemProps, Icon, css } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';

import {
  Heading,
  Text,
  FixedRatio,
  Button,
  Container,
  Divider,
  ScaleOnHover,
  HoverArea,
} from '~components';
import { Instagram } from '~components/common/Icons';
import { InstagramComponent } from '~lib/storyblok/sdk';

interface Props extends InstagramComponent, SystemProps {
  divider?: boolean;
}

export const InstagramOverview = ({
  title,
  description,
  photos,
  ctaText,
  ctaUrl,
  divider = true,
  ...props
}: Props) => {
  return (
    <Container
      as="section"
      textAlign="center"
      pt={0}
      pb={[8, 10]}
      px={[2, 6]}
      {...props}
    >
      {divider && <Divider mb={[8, 10]} />}
      {!!title && (
        <Heading
          variant="h1"
          as="h2"
          mb={!!description ? [1, 2] : { all: 4, md: 5 }}
          flex={1}
        >
          {title}
        </Heading>
      )}
      {!!description && (
        <Text fontSize={[2, 2.25]} mb={{ all: 4, md: 5 }}>
          {description}
        </Text>
      )}

      <Box
        display="grid"
        gridTemplateColumns={{ all: 'repeat(2, 2fr)', md: 'repeat(4, 1fr)' }}
        gridTemplateRows={{ all: 'repeat(2, 2fr)', md: '1fr' }}
        gridGap={{ all: 2, sm: 3 }}
        maxWidth={{ sm: '70%', md: '100%' }}
        m="0 auto"
      >
        {photos?.map(({ photo, username, _uid }, idx) => (
          <Fragment key={_uid}>
            <HoverArea>
              <Button
                key={_uid}
                variant="unstyled"
                to={ctaUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box textAlign="left">
                  <FixedRatio
                    borderRadius="lg"
                    position="relative"
                    overflow="hidden"
                    css={css({
                      '.storyblok-image-wrapper': {
                        width: '100%',
                        height: '100%',
                        img: { borderRadius: 'lg' },
                      },
                    })}
                  >
                    <ScaleOnHover>
                      <Image
                        src={photo.filename}
                        alt={photo.alt || 'instagram_photo'}
                        fluid={[302, 302]}
                        focus={photo.focus}
                      />
                    </ScaleOnHover>
                    <Icon
                      icon={Instagram}
                      fontSize={{ all: 3, md: 4 }}
                      color="white"
                      position="absolute"
                      top={{ all: 2, md: 3 }}
                      right={{ all: 2, md: 3 }}
                    />
                  </FixedRatio>
                  <Text mt={1} ml={0}>
                    {username}
                  </Text>
                </Box>
              </Button>
            </HoverArea>
          </Fragment>
        ))}
      </Box>

      {ctaText && ctaUrl && (
        <Button
          mt={[6, 8]}
          variant="outline"
          to={ctaUrl}
          target="_blank"
          rel="noopener noreferrer"
          width={{ md: 'auto' }}
          display={{ sm: 'inline-flex' }}
        >
          {ctaText}
        </Button>
      )}
    </Container>
  );
};
