import { Box, Flex, SystemProps, css } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';

import { darken } from 'polished';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Button } from '~components/common/Button';
import { Heading } from '~components/common/Heading';
import { Link } from '~components/common/Link';
import { Text } from '~components/common/Text';

type CocktailListingProps = {
  backgroundColor?: Record<string, any>;
  title: string;
  description: string;
  image: { alt?: string; filename: string };
  url: string;
  size?: Array<string | number>;
} & SystemProps;

const Wrapper = styled(Box)``;

const HoverButton = styled(Button)`
  ${Wrapper}:hover & {
    background-color: ${(p) => p.theme.colors.grey900};
    color: #fff;
    border: 1px solid;
    border-color: ${(p) => p.theme.colors.grey900};

    &:hover &:focus {
      background-color: ${(p) => p.theme.colors.grey400};
    }

    &:active {
      box-shadow: 0px 0px 0px 4px #d9e1dd;
    }

    &:disabled:not([data-is-loading]) {
      opacity: 0.25;
    }
  }
`;

export const CocktailListing = ({
  backgroundColor,
  title,
  description,
  image,
  url,
  size = ['240px', '480px'],
  ...props
}: CocktailListingProps) => {
  const intl = useIntl();

  return (
    <Box>
      <Link href={url} passHref>
        <Wrapper
          as="a"
          css={css({
            '&:hover': {
              '.storyblok-image-wrapper': {
                transform: 'translateY(-5%)',
              },
              '.cocktail-bg': {
                background: darken(0.15, backgroundColor?.color),
              },
            },
          })}
        >
          <Flex
            position="relative"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            {...props}
          >
            <Box
              width="100%"
              position="relative"
              borderTopLeftRadius="lg"
              borderTopRightRadius="lg"
              overflow="hidden"
            >
              <Box
                bg={backgroundColor?.color}
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                className="cocktail-bg"
                transition="background 0.32s ease-in-out"
                opacity="0.5"
                css={css({
                  content: "''",
                })}
              />
              <Flex
                position="relative"
                alignItems="center"
                justifyContent="center"
                height={size}
                width="100%"
                borderRadius="md"
                overflow="hidden"
                mb={3}
                css={css({
                  '.storyblok-image-wrapper': {
                    width: '100%',
                    height: size,
                    transition: 'transform 0.32s ease-in-out',
                    img: {
                      borderRadius: 'md',
                      objectFit: 'contain !important',
                    },
                  },
                })}
              >
                <Image
                  src={image?.filename}
                  alt={image?.alt || `${title}-image`}
                  fluid={410}
                  showPlaceholder={false}
                />
              </Flex>
              <Box
                height="104px"
                css={css({
                  background:
                    'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
                })}
                transform="matrix(1, 0, 0, -1, 0, 0)"
                width="100%"
                zIndex={1}
                position="absolute"
                left={0}
                right={0}
                bottom={0}
              />
            </Box>
            <Box textAlign="center">
              <Heading
                variant="h1"
                fontSize={[3, '28px']}
                lineHeight="high"
                mb={1}
              >
                {title}
              </Heading>
              <Text
                fontSize={2}
                lineHeight="high"
                color="grey500"
                maxWidth="320px"
                maxHeight="48px"
                minHeight="48px"
                overflow="hidden"
                css={{
                  display: '-webkit-box',
                  textOverflow: 'ellipsis',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical',
                }}
              >
                {description}
              </Text>
              <HoverButton variant="outline" mx="auto" mt={4}>
                {intl.formatMessage({
                  defaultMessage: 'View Cocktail',
                })}
              </HoverButton>
            </Box>
          </Flex>
        </Wrapper>
      </Link>
    </Box>
  );
};
