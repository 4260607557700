import { Box, Flex, css } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';
import { MARK_BOLD } from 'storyblok-rich-text-react-renderer';

import { Text, RichText } from '~components';
import { ProductItem } from '~lib/storyblok/sdk';

interface Props {
  recipe?: Partial<ProductItem['content']>;
}

export const Recipe = ({ recipe }: Props) => {
  return (
    <>
      <Box display={{ md: 'flex' }} alignItems="center">
        {recipe?.image_section_image?.filename && (
          <Flex
            width="100%"
            maxHeight={{ all: '165px', md: '460px' }}
            borderRadius="lg"
            mb={3}
            flex={1}
            css={css({
              '.storyblok-image-wrapper': {
                maxHeight: '100%',
                width: '100%',
                img: { borderRadius: 'lg', objectFit: 'contain !important' },
              },
            })}
          >
            <Image
              src={recipe?.image_section_image?.filename}
              alt={
                recipe?.image_section_image?.alt || recipe?.image_section_title
              }
              fluid={440}
            />
          </Flex>
        )}

        <Flex
          flexDirection="column"
          alignItems="center"
          flex={1}
          maxWidth={{ lg: '440px' }}
          textAlign={{ md: 'left' }}
          ml={{ md: 3, lg: 4 }}
        >
          {!!recipe?.image_section_text && (
            <RichText
              width="100%"
              text={recipe.image_section_text}
              markResolvers={{
                [MARK_BOLD]: (children) => (
                  <Text as="span" fontWeight="semiBold">
                    {children}
                  </Text>
                ),
              }}
            />
          )}
        </Flex>
      </Box>
    </>
  );
};
