import { useState } from 'react';
import { Box, Flex } from '@storyofams/react-ui';
import { AnimatePresence, m } from 'framer-motion';
import { useMeasure } from 'react-use';

import { Button, RichText, Divider } from '~components';

interface Props {
  text?: string;
}

const MotionButton = m(Button);

export const Flavour = ({ text }: Props) => {
  const [open, setOpen] = useState(false);
  const [ref, { height }] = useMeasure();

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-end"
      flex={1}
      maxWidth={{ lg: '440px' }}
      mb={{ all: 10, md: 0 }}
    >
      <Box width="100%" maxWidth={{ lg: '440px' }}>
        <Box
          maxHeight={open ? `${height}px` : '90px'}
          transition="max-height ease 0.3s"
          overflow="hidden"
        >
          <div ref={ref}>
            <RichText text={text} />
          </div>
        </Box>

        <AnimatePresence>
          {height && height > 90 && (
            <MotionButton
              width="100%"
              variant="unstyled"
              onClick={() => setOpen(!open)}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              duration={0.2}
              transition={{ ease: 'linear' }}
            >
              <Box
                position="absolute"
                top="-32px"
                height="32px"
                left="0"
                right="0"
                css={{
                  background: open
                    ? 'transparent'
                    : ('linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFF 100%)' as any),
                }}
              />
              <Divider mt={1} color="grey500">
                {open ? 'Show less' : 'Read more'}
              </Divider>
            </MotionButton>
          )}
        </AnimatePresence>
      </Box>
    </Flex>
  );
};
