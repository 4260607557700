import { Flex, Icon, css } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';

import { Heading, ScaleOnHover, HoverArea, Link } from '~components';
import { Emblem } from '~components/common/Icons';

export type CollectionButtonProps = {
  title: string;
  image?: {
    src: string;
    focus?: string;
  };
  href: string;
};

export const CollectionButton = ({
  image,
  title,
  href,
}: CollectionButtonProps) => (
  <Link href={href} passHref>
    <a>
      <HoverArea>
        {image ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            height={['240px', '280px']}
            width="100%"
            borderRadius="md"
            overflow="hidden"
            mb={3}
            css={css({
              '.storyblok-image-wrapper': {
                width: '100%',
                height: '100%',
                img: {
                  borderRadius: 'md',
                },
              },
            })}
          >
            <ScaleOnHover>
              <Image {...image} alt={title} fluid={[250, 280]} />
            </ScaleOnHover>
          </Flex>
        ) : (
          <Flex
            variant="center"
            bg="grey200"
            height={['240px', '280px']}
            width="100%"
            borderRadius="md"
            mb={3}
          >
            <Icon fontSize={18} icon={Emblem} />
          </Flex>
        )}
        <Heading
          variant="h4"
          as="h2"
          mx="auto"
          fontSize={2.5}
          lineHeight="normal"
        >
          {title}
        </Heading>
      </HoverArea>
    </a>
  </Link>
);
