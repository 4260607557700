import React from 'react';

import { Divider } from '~components';
import { Review, ReviewsOverview } from '..';

export const Reviews = ({ reviews, detailedReviews }) => {
  return (
    <>
      {reviews?.numberOfReviews?.total > 0 && (
        <ReviewsOverview
          reviews={reviews}
          flexDirection="column"
          color="grey900"
          id="reviews"
        />
      )}
      <Divider mt={6} mb={4} />

      {detailedReviews
        ?.filter((review) => review.stars > 3)
        .slice(0, 3)
        .map((review, idx) => (
          <React.Fragment key={`${review.stars}-${idx}`}>
            <Review {...review} />
            <Divider my={5} mb={{ md: 4 }} />
          </React.Fragment>
        ))}
    </>
  );
};
