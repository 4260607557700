import { ReactNode } from 'react';
import { Box, SystemProps } from '@storyofams/react-ui';

import { Heading } from '~components';

interface SectionProps extends SystemProps {
  title: string;
  children: ReactNode;
}

export const Section = ({ title, children, mb, ...props }: SectionProps) => {
  return (
    <Box as="section" textAlign="center" {...props}>
      <Heading variant="h1" as="h2" mb={mb || [2, 5]} flex={1}>
        {title}
      </Heading>

      {children}
    </Box>
  );
};
