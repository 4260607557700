import { useEffect, useState, createRef } from 'react';
import { throttle } from 'lodash';

/**
 * Check if an element is in viewport
 *
 * @param {number} throttleMS - Number of milliseconds to throttle invocations to.
 */
export const useVisibility = (
  throttleMS = 100,
): [Boolean, React.RefObject<Element>] => {
  const [isVisible, setIsVisible] = useState(false);
  const currentElement = createRef<Element>();

  const onScroll = throttle(() => {
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }
    const rect = currentElement.current.getBoundingClientRect();
    const topIsWithin = rect.top >= -(rect.height * 0.75);
    const bottomIsWithin = rect.top * 1.25 <= window.innerHeight;

    setIsVisible(topIsWithin && bottomIsWithin);
  }, throttleMS);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  });

  return [isVisible, currentElement];
};
