import { useEffect, useState } from 'react';
import { Box } from '@storyofams/react-ui';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useIntl, defineMessages } from 'react-intl';

import { Text, Heading, FixedRatio } from '~components';
import { useVisibility } from '~hooks/useVisibility';
import { track } from '~lib/gtag';
import { decodeShopifyId, shopifyToIntlPrice } from '~lib/shopify/utils';

const messages = defineMessages({
  from: 'From',
});

export const ProductSlide = ({
  handle,
  image,
  title,
  priceRange,
  bg,
  readyToEnjoy,
  variants,
  position,
  id,
  tags,
}) => {
  const intl = useIntl();
  const { asPath } = useRouter();
  const [isVisible, currentElement] = useVisibility();
  const [hasBeenTracked, setHasBeenTracked] = useState<boolean>(false);

  let readyToEnjoyValue = readyToEnjoy;
  if (readyToEnjoy !== null && readyToEnjoy['value'] !== undefined) {
    readyToEnjoyValue = readyToEnjoy['value'] === 'false' ? false : true;
  }

  useEffect(() => {
    if (isVisible && !hasBeenTracked) {
      setHasBeenTracked(true);
      track({
        event: 'productImpression',
        ecommerce: {
          price: priceRange?.minVariantPrice.amount,
          currencyCode: priceRange?.minVariantPrice.currencyCode,
          impressions: [
            {
              id: decodeShopifyId(id),
              name: title,
              price: priceRange?.minVariantPrice.amount,
              brand: 'BOLS',
              category: tags
                ?.find((tag) => tag?.startsWith('collection_'))
                ?.replace('collection_', ''),
              position: position,
              list:
                asPath === '/'
                  ? 'home'
                  : asPath.includes('shop')
                  ? 'category'
                  : 'related_products',
            },
          ],
        },
      });
    }
  }, [isVisible]);

  return (
    <Box {...({ ref: currentElement } as any)}>
      <Link href={`/products/${handle}`} key={title} passHref>
        <Box
          as="a"
          onClick={() => {
            track({
              event: 'productClick',
              ecommerce: {
                value: priceRange?.minVariantPrice.amount,
                currencyCode: priceRange?.minVariantPrice.currencyCode,
                click: {
                  actionField: {
                    list: asPath === '/' ? 'home' : 'related_products',
                  },
                  products: [
                    {
                      id: decodeShopifyId(id),
                      name: title,
                      price: priceRange?.minVariantPrice.amount,
                      brand: 'BOLS',
                      category: tags
                        ?.find((tag) => tag?.startsWith('collection_'))
                        ?.replace('collection_', ''),
                      position: position,
                    },
                  ],
                },
              },
            });
          }}
        >
          <FixedRatio
            css={{
              backgroundImage: readyToEnjoyValue && `url('${bg}')`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            borderRadius="lg"
            alignSelf="center"
            width="100%"
            mb={3}
          >
            {image && (
              <Box
                as={'img' as any}
                src={image}
                alt={title}
                css={{ objectFit: 'contain' }}
                width="100%"
                height="100%"
                borderRadius="lg"
              />
            )}
          </FixedRatio>

          <Heading variant="h5" as="h5" fontSize={{ md: '28px' }} mb={1}>
            {title}
          </Heading>

          <Text variant="s">
            {priceRange &&
              `${
                variants?.edges?.length > 1
                  ? `${intl.formatMessage(messages.from)} `
                  : ''
              }${shopifyToIntlPrice(priceRange?.minVariantPrice)}`}
          </Text>
        </Box>
      </Link>
    </Box>
  );
};
