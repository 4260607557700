import { Flex, css, SystemProps } from '@storyofams/react-ui';
import { pick, isEmpty } from 'lodash';

import { Slider } from '~components';
import { CocktailListing } from '~components/cocktails/CocktailListing';
import { SliderProps } from '~components/common/Slider';
import { CocktailItem, Story } from '~lib/storyblok/sdk';

interface CocktailSliderProps extends SystemProps {
  cocktails?: CocktailItem[] | Story[];
  country: string;
}

export const CocktailSlider = ({
  cocktails,
  country,
  ...props
}: CocktailSliderProps) => {
  const getCocktailLink = (content, full_slug, fullSlug) => {
    let link = `/${fullSlug || full_slug}`;

    if (
      country &&
      !['NL', 'US'].includes(country) &&
      !isEmpty(content?.rowUrl)
    ) {
      link =
        content?.rowUrl?.linktype === 'story'
          ? `/${content?.rowUrl?.story?.fullSlug}`
          : content?.rowUrl?.cached_url;
    }
    return link;
  };

  return (
    <Flex
      variant="center"
      css={css({
        '.swiper-button-next, .swiper-button-prev': {
          top: ['150px', '190px'],
        },
        '.swiper-slide': { width: ['inherit', '430px'] },
      })}
      {...props}
    >
      <Slider
        centerInsufficientSlides
        breakpoints={{
          1024: {
            spaceBetween: 24,
          },
          768: {
            spaceBetween: 32,
          },
          640: {
            spaceBetween: 32,
            slidesPerView: 1,
          },
          320: {
            spaceBetween: 32,
          },
        }}
        {...(pick(props, ['breakpoints']) as SliderProps)}
      >
        {cocktails?.map(({ content, full_slug, fullSlug }) => (
          <CocktailListing
            key={content?._uid}
            title={content?.title}
            description={content?.description}
            backgroundColor={content?.backgroundColor}
            image={content?.image}
            url={getCocktailLink(content, full_slug, fullSlug)}
            size={['300px', '380px']}
          />
        ))}
      </Slider>
    </Flex>
  );
};
