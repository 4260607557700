import { css, Box, Flex, SystemProps } from '@storyofams/react-ui';

import { Slider, Container, Heading } from '~components';

import { CollectionButton } from '../products/CollectionButton';

export type CollectionSectionProps = {
  items: Record<string, any>[];
  prefix?: string;
  title?: string;
} & SystemProps;

export const CollectionSection = ({
  items,
  title,
  prefix,
  ...props
}: CollectionSectionProps) => {
  return items?.length ? (
    <Box width="100%" overflow="hidden">
      <Container
        as="section"
        textAlign="center"
        py={[8, 10]}
        px={[2, 6]}
        {...props}
      >
        {!!title && (
          <Heading variant="h1" as="h2" mb={{ all: 4, md: 5 }} flex={1}>
            {title}
          </Heading>
        )}

        <Flex
          variant="center"
          css={css({
            '.swiper-slide': {
              width: ['203px', '249px'],
            },
          })}
        >
          <Slider centerInsufficientSlides>
            {items
              .filter(({ slug }) =>
                process.env.NEXT_PUBLIC_STORE_COUNTRY === 'us'
                  ? slug !== 'cocktail-tools'
                  : true,
              )
              .map(({ content, full_slug }) => {
                let slug = full_slug;
                if (
                  process.env.NEXT_PUBLIC_STORE_COUNTRY === 'us' &&
                  slug.startsWith('us/')
                ) {
                  slug = slug.replace('us/', '');
                }

                return (
                  <CollectionButton
                    key={content._uid}
                    title={content.title}
                    image={
                      content.image?.filename
                        ? {
                            src: content.image.filename,
                            focus: content.image.focus,
                          }
                        : undefined
                    }
                    href={`/${slug}`}
                  />
                );
              })}
          </Slider>
        </Flex>
      </Container>
    </Box>
  ) : null;
};
