import React, { useEffect, useState } from 'react';
import { Flex, Box } from '@storyofams/react-ui';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  Container,
  Heading,
  Image,
  Text,
  FixedRatio,
  BackButton,
  Button,
  Slider,
} from '~components';
import { shopifyToIntlPrice } from '~lib/shopify/utils';

import { ReviewsOverview } from './Reviews';

const Wrapper = styled(Box)`
  position: relative;

  &:after {
    content: '';
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);
    height: 170px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
`;

const messages = defineMessages({
  back: 'Back to overview',
});

export const Header = ({
  product,
  selectedVariant,
  content,
  reviews,
  collections,
  subcollections,
  children,
}) => {
  const [showSlider, setShowSlider] = useState(true);

  const firstTag = product?.tags
    ?.find((t) => t?.startsWith('collection_'))
    ?.split('collection_')?.[1];

  const getCollection = () =>
    process.env.NEXT_PUBLIC_STORE_COUNTRY === 'b2b'
      ? { full_slug: 'shop' }
      : subcollections?.items?.find(({ content }) =>
          product?.tags?.find(
            (t) => t === `collection_${content?.collection?.item}`,
          ),
        ) ||
        collections?.items?.find(({ content }) =>
          product?.tags?.find(
            (t) => t === `collection_${content?.collection?.item}`,
          ),
        ) ||
        collections?.items?.find(
          ({ content }) => content?.collection?.item === firstTag,
        ) ||
        {};

  useEffect(() => {
    setShowSlider(false);
  }, [product]);

  useEffect(() => {
    if (!showSlider) {
      setShowSlider(true);
    }
  }, [showSlider]);

  return (
    <Wrapper
      bg={
        product?.readyToEnjoy?.value === 'true'
          ? (content?.background_color?.color as any)
          : undefined
      }
    >
      <Container pt={[3, 6]} pb={0} maxWidth="1360px" px={[1, 2, 5]}>
        <Box mb={3} display="inline-block" width={['100%', '100%', 'auto']}>
          <BackButton to={`/${getCollection()?.full_slug || ''}`}>
            <FormattedMessage {...messages.back} />
          </BackButton>
        </Box>
      </Container>

      <Container
        pt={0}
        px={{ all: 2, md: 8, lg: '204px' }}
        pb={0}
        position="relative"
        zIndex={2}
      >
        <Flex
          overflow="hidden"
          flexDirection={{ all: 'column', md: 'row' }}
          justifyContent="space-between"
          mb={{ all: 18, md: '104px' }}
          width="100%"
        >
          <Flex
            mb={{ all: 3, md: 0 }}
            pr={{ md: 12, lg: 10 }}
            width={{ all: '100%', md: '50%' }}
            maxWidth={{ sm: '50vh', md: '50%' }}
            flex={1}
            overflow="hidden"
            alignSelf={{ all: 'center', md: 'flex-start' }}
          >
            {showSlider && (
              <Slider
                slidesPerView={1}
                effect="fade"
                fadeEffect={{ crossFade: true }}
              >
                {product?.images?.edges?.map(({ node }, i) => (
                  <FixedRatio
                    width="100%"
                    alignSelf="center"
                    key={`${product?.id}-image-${i}`}
                  >
                    <Image
                      height="100%"
                      width="100%"
                      src={node?.url}
                      alt={
                        (node?.altText || selectedVariant?.title) ??
                        'product_image'
                      }
                      layout="fill"
                      objectFit="contain"
                      overflow="hidden"
                      borderRadius="lg"
                    />
                  </FixedRatio>
                ))}
              </Slider>
            )}
          </Flex>
          <Box
            flex={1}
            width={{ all: '100%', md: '50%' }}
            maxWidth={{ lg: '440px' }}
            textAlign={{ all: 'center', md: 'left' }}
          >
            <Text
              variant="tagline"
              mb={{ all: 1, md: 2 }}
              letterSpacing="0.04em"
              fontWeight="regular"
            >
              {selectedVariant?.compareAtPriceV2 ? (
                // @ts-ignore
                <Box
                  // @ts-ignore
                  as="strike"
                  mr={1}
                >
                  {shopifyToIntlPrice(selectedVariant?.compareAtPriceV2)}
                </Box>
              ) : (
                ''
              )}
              {selectedVariant?.priceV2
                ? shopifyToIntlPrice(selectedVariant?.priceV2)
                : ''}
              {product?.variants?.edges?.length > 1 &&
                ` - ${selectedVariant?.title}`}
            </Text>

            <Heading variant="h1" mb={1}>
              {product?.title}
            </Heading>

            {reviews?.numberOfReviews?.total > 0 && (
              <Button
                variant="unstyled"
                onClick={() => {
                  const el = document.getElementById('reviews');
                  if (el) {
                    el.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                <ReviewsOverview reviews={reviews} mb={2} width="100%" />
              </Button>
            )}

            <Box mb={{ all: 6, md: 5 }}>
              <Text
                // @ts-ignore
                as="div"
                lineHeight="140%"
                dangerouslySetInnerHTML={{ __html: product?.descriptionHtml }}
              />

              {!!product?.content?.value && (
                <Text mt={1} fontStyle="italic" color="cocktails">
                  {product.content.value}
                </Text>
              )}
            </Box>

            {children}
          </Box>
        </Flex>
      </Container>
    </Wrapper>
  );
};
