import { Box, SystemProps, css } from '@storyofams/react-ui';
import { Container, Link } from '~components';
import { Image } from './Image';

interface GalleryProps extends SystemProps {
  images: any[];
  alignment?: 'landscapeLeft' | 'landscapeRight';
  asCard?: boolean;
}

export const Gallery = ({
  images,
  alignment,
  asCard,
  ...props
}: GalleryProps) => {
  const typeByIndex =
    images?.length === 1
      ? ['landscape']
      : alignment === 'landscapeLeft'
      ? ['landscape', 'portrait']
      : ['portrait', 'landscape'];

  return (
    <Container
      px={{ all: 0, sm: 5, md: 8, lg: 10 }}
      pt={0}
      pb={0}
      css={css({ '&:not(:last-of-type)': { marginBottom: [10, 15] } })}
    >
      <Box
        display={{ sm: images?.length === 1 ? 'flex' : 'grid' }}
        alignItems="center"
        justifyContent="center"
        gridTemplateColumns={
          alignment === 'landscapeLeft' ? '13fr 10fr' : '10fr 13fr'
        }
        gridColumnGap={12}
        {...props}
      >
        {images?.map(({ link, _uid, ...rest }, idx) =>
          link?.story?.url ? (
            <Link key={_uid} href={`/${link?.story?.url}`} passHref>
              <Image
                as="a"
                alignment={images?.length === 1 ? 'center' : alignment}
                type={typeByIndex[idx]}
                asCard={asCard}
                asLink
                {...rest}
              />
            </Link>
          ) : (
            <Image
              key={_uid}
              alignment={images?.length === 1 ? 'center' : alignment}
              type={typeByIndex[idx]}
              asCard={asCard}
              {...rest}
            />
          ),
        )}
      </Box>
    </Container>
  );
};
