import { ReactNode } from 'react';
import { Box, Flex, SystemProps, css } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';

import ReactPlayer from 'react-player/lazy';
import { Heading, Container, Video } from '~components';

type HeaderProps = {
  children: ReactNode;
  imgSrc?: any;
  media?: any;
  preText?: string;
} & SystemProps;

export const Hero = ({
  children,
  media,
  imgSrc,
  preText,
  ...props
}: HeaderProps) => (
  <>
    <Container
      pt={[0, 0, 1.25]}
      maxWidth="1360px"
      px={{ all: 2, md: 5, lg: 5 }}
      pb={0}
    >
      <Box
        display={['none', 'none', 'block']}
        width="100%"
        borderTop="1px"
        borderColor="grey200"
      />
    </Container>

    <Container {...props} px={{ all: 2, md: 6, lg: 6 }} pt={0}>
      {preText && (
        <Heading
          as="h2"
          variant="h4"
          mb={3}
          pt={3}
          pb={2.5}
          width="100%"
          textAlign="center"
          fontSize={2}
          lineHeight="140%"
          borderBottom="1px"
          borderColor="grey200"
          css={{ textTransform: 'uppercase' }}
        >
          {preText}
        </Heading>
      )}

      <Flex
        flex={1}
        position="relative"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        minHeight="400px"
        overflow="hidden"
        borderRadius="lg"
        py={10}
        px={2}
        css={{
          '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
          background:
            'linear-gradient(0.27deg, #331B11 0.23%, #331B11 0.24%, rgba(51, 27, 17, 0) 68.83%)',
        }}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          css={css({
            '.storyblok-image-wrapper': {
              height: '100%',
              img: { borderRadius: 'lg' },
            },
          })}
          bg="grey900"
          zIndex="hide"
          display="flex"
          alignItems="center"
        >
          {(!!media?.imgSrc || !!imgSrc) && (
            <Image
              src={imgSrc?.filename || media?.imgSrc?.filename}
              alt={imgSrc?.alt || media?.imgSrc?.alt}
              focus={imgSrc?.focus || media?.imgSrc?.focus}
              fluid={[1344, 400]}
              lazy={false}
            />
          )}

          {!!media?.vimeoUrl && (
            <Video
              url={media?.vimeoUrl}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    controls: false,
                    disablekb: 1,
                    loop: 1,
                  },
                },
                vimeo: {
                  playerOptions: {
                    responsive: true,
                  },
                },
              }}
              controls={false}
              playing={true}
              loop={true}
              volume={0}
              muted={true}
              width="100%"
            />
          )}
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="espressoMartini"
            opacity="0.5"
          />
        </Box>
        {children}
      </Flex>
    </Container>
  </>
);
