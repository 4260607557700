import { Box, SystemProps } from '@storyofams/react-ui';
import { useRouter } from 'next/router';
import SbEditable from 'storyblok-react';

import { Heading, Divider, Container, Button } from '~components';
import { useShopify } from '~context';
import { CocktailSlider } from '~components/cocktails/CocktailSlider';
import { CocktailItem, Story } from '~lib/storyblok/sdk';

type CocktailSectionProps = {
  first?: boolean;
  content?: {
    title?: string;
    _editable?: string;
    cocktails?: CocktailItem[] | Story[];
  };
  divider?: boolean;
  button?: any;
} & SystemProps;

export const CocktailSection = ({
  first,
  divider = false,
  content,
  button,
  ...props
}: CocktailSectionProps) => {
  const { locale } = useRouter();
  const { country } = useShopify();

  const getCocktailsOverviewLink = () => {
    let link =
      locale === 'nl'
        ? '/signature-cocktail-recepten'
        : '/signature-cocktail-recipes';
    if (country && !['NL', 'US'].includes(country)) {
      link = 'https://bols.com/cocktails/recipes';
    }

    return link;
  };

  return (
    <SbEditable content={content as any}>
      <Box width="100%" overflow="hidden">
        <Container
          as="section"
          textAlign="center"
          pt={0}
          pb={[8, 10]}
          px={[2, 6]}
          {...props}
        >
          {divider && <Divider mb={[8, 10]} />}

          {!!content?.title && (
            <Heading
              as={first ? 'h1' : 'h2'}
              variant="h1"
              mb={{ all: 4, md: 5 }}
              maxWidth="720px"
              mx="auto"
            >
              {content?.title}
            </Heading>
          )}
          {!!content?.cocktails && (
            <CocktailSlider cocktails={content?.cocktails} country={country} />
          )}
          {button && (
            <Button
              alignSelf="center"
              display="inline-flex"
              variant="outline"
              to={getCocktailsOverviewLink()}
              mt={[3, 5]}
            >
              {button}
            </Button>
          )}
        </Container>
      </Box>
    </SbEditable>
  );
};
