import React, { useState } from 'react';
import { Icon } from '@storyofams/react-ui';

import { Text } from '~components';
import { ChevronRight } from '~components/common/Icons';

export const TruncatedText = ({ maxLines, children }) => {
  const [className, setClassName] = useState('truncate');

  const multiLineOverflows = () => {
    const el = document.getElementById('truncated');

    return el?.scrollHeight && el?.clientHeight
      ? el?.scrollHeight > el?.clientHeight
      : false;
  };

  const toggleClassName = () => setClassName(!!className ? '' : 'truncate');

  return (
    <Text
      id="truncated"
      mb={1}
      {...(multiLineOverflows()
        ? {
            onClick: toggleClassName,
            className,
            pr: 1.75,
            '&:hover': { cursor: 'pointer' },
          }
        : {})}
      css={{
        '&.truncate': {
          position: 'relative',
          overflow: 'hidden',
          display: '-webkit-box',
          textOverflow: 'ellipsis',
          '-webkit-line-clamp': maxLines,
          '-webkit-box-orient': 'vertical',
        },
      }}
    >
      {children}

      {multiLineOverflows() && (
        <Icon
          icon={ChevronRight}
          position="absolute"
          bottom="5px"
          right={0}
          fontSize={1.5}
        />
      )}
    </Text>
  );
};
